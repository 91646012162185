import axios from 'axios';
import {apiUrl} from '@/env';
import {IUserProfile, IUserProfileCreate, IUserProfileUpdate} from './interfaces';

function authHeaders(token: string) {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
}

function uploadFileAuthHeaders(token: string) {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    };
}

export const api = {
    async logInGetToken(username: string, password: string) {
        const params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);

        return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
    },
    async getMe(token: string) {
        return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
    },
    async updateMe(token: string, data: IUserProfileUpdate) {
        return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
    },
    async getUsers(token: string) {
        return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
    },
    async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
        return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
    },
    async createUser(token: string, data: IUserProfileCreate) {
        return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
    },
    async passwordRecovery(email: string) {
        return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
    },
    async resetPassword(password: string, token: string) {
        return axios.post(`${apiUrl}/api/v1/reset-password/`, {
            new_password: password,
            token,
        });
    },
    fileMetadata(data: any, token: string) {
        var formData = new FormData();
        formData.append("file", data);
        return axios.post(`${apiUrl}/api/v1/files/metadata`, formData, uploadFileAuthHeaders(token));
    },
    fileSave(data: any, token: string) {
        var formData = new FormData();
        formData.append("file", data);
        return axios.post(`${apiUrl}/api/v1/files/`, formData, uploadFileAuthHeaders(token));
    },
    fileDownload(id: string, token: string) {
        let header = authHeaders(token)
        header['responseType'] = 'arraybuffer'
        return axios.get(`${apiUrl}/api/v1/files/${id}`, header);
    },
    fileDownloadTemplate(id: string, token: string) {
        let header = authHeaders(token)
        header['responseType'] = 'arraybuffer'
        return axios.get(`${apiUrl}/api/v1/files/templates/${id}`, header);
    },
    templatesMetadata(token: string) {
        return axios.get(`${apiUrl}/api/v1/files/searches/templates/metadata`, authHeaders(token));
    },
    async templatesMetadataKeys(token: string) {
        return axios.get(`${apiUrl}/api/v1/files/searches/templates/metadata-keys`, authHeaders(token));
    },
    async metadataValues(token: string) {
        return axios.get(`${apiUrl}/api/v1/files/searches/templates/metadata-values`, authHeaders(token));
    },
    searchMetadataAllFields(token: string, query: string) {
        const params = authHeaders(token);
        params['params'] = {query: query};
        return axios.get(`${apiUrl}/api/v1/files/searches/templates/all-metadata-fields`, params);
    },
     searchMetadataFields(token: string, query: URLSearchParams) {
        const params = authHeaders(token);
        params['params'] = query;
        return axios.get(`${apiUrl}/api/v1/files/searches/templates/metadata-fields`, params);
    },
};
