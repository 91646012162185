import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, {
    iconfont: 'md',
    theme: {
        themes: {
            light: {
                primary: '#36a9e1',
                secondary: '#1d71b8',
                accent: '#706f6f',
                error: '#b71c1c',
            },
        },
    },
});
